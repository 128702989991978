<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">{{title}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="80" :key="form.parentId">
      <FormItem prop="groupId" label="所属分区">
        <Select ref="groupId" v-model="form.groupId" number>
          <template v-for="(item, idx) in allGroups">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="name" label="网关名称">
        <Input ref="name" type="text" v-model="form.name"></Input>
      </FormItem>
      <FormItem prop="deviceType" label="设备类型">
        <Select ref="deviceType" v-model="form.deviceType" number>
          <template v-for="(item, idx) in smallTypes">
            <Option :value="item.code" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="code" label="通信ID">
        <Input ref="code" type="text" maxlength="20" v-model="form.code" placeholder="请输入设备通信ID"></Input>
      </FormItem>
      <FormItem prop="iccid" label="ICCID">
        <Input ref="iccid" type="text" v-model="form.iccid"></Input>
      </FormItem>
      <FormItem prop="buildDate" :label="showLang('com.date.installation')">
        <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
      </FormItem>
      <FormItem prop="location" label="安装位置">
        <Input ref="location" type="text" v-model="form.location"></Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalGatewayEdit',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    smallTypes: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      title: '编辑单灯网关信息',
      loading: false,
      showModal: this.value,
      isAdd: false,
      devtype: '',
      form: {
        id: '',
        name: '',
        code: '',
        groupId: 0,
        deviceType: 0,
        iccid: '',
        buildDate: '',
        location: '',
      },
      rules: {
        name: { required: true, type: 'string', max: 30, message: '设备名称不能为空且长度不能超过30个字符', trigger: 'blur' },
        code: { required: true, type: 'string', max: 30, message: '通信ID不能为空且长度不能超过30个字符', trigger: 'blur' },
        // type: { required: true, type: 'string', message: '必须选择一个设备类型', trigger: 'on-change' },
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('group', ['groupTreeSelectedNode']),
    allGroups: function(){
      // console.log('all groups', this.groupTreeSelectedNode)
      if(!this.groupTreeSelectedNode.children)return[];
      let grps = [];
      if(this.groupTreeSelectedNode.id > 0){
        grps.push({id: this.groupTreeSelectedNode.id, name: this.groupTreeSelectedNode.title});
      }
      for(let node of this.groupTreeSelectedNode.children){
        this.getChildGroups(grps, node);
      }
      return grps;
    },
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('edit device', this.item)
        this.isAdd = this.item.isAdd;
        if (this.isAdd) {
          this.form.id = "";
          this.form.name = '';
          this.form.code = "";
          this.form.groupId = this.allGroups.length > 0 ? this.allGroups[0].id : 0;
          this.form.deviceType = this.smallTypes.length > 0 ? this.smallTypes[0].code : 0;
          this.form.iccid = '';
          this.form.buildDate = new Date().format('yyyy-MM-dd');
          this.form.location = '';
        } else {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.code;
          this.form.groupId = this.item.data.groupId;
          this.form.deviceType = this.item.data.deviceType;
          this.form.iccid = this.item.data.iccid;
          this.form.buildDate = this.item.data.buildDate;
          this.form.location = this.item.data.location;
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {

  },
  methods: {
    getChildGroups: function(list, node){
      list.push({id: node.id, name: node.title});
      for(let n of node.children){
        this.getChildGroups(list, n);
      }
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      if (this.form.code == '') {
        this.$Message.warning('请输入通信ID')
        return;
      }
      this.loading = true;
      this.$axios.post(`device/light/SaveGateWay`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$Message.info('保存成功');
          setTimeout(() => {
            this.showModal = false;
            this.$emit('saved');
          }, 800);
        } else {
          // this.$Message.error(res.data);
        }
      });
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>