<template>
<div class="config-device-container">
  <div class="config-tree-area">
    <GroupTree />
  </div>
  <div class="device-data-area">
    <div class="device-search-area">
      <span>{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 140px; margin-right: 5px;"></Input>
      <span>通信ID：</span>
      <Input type="text" v-model="filter.code" clearable placeholder="通信ID" style="width: 140px; margin-right: 5px;"></Input>
      <Button type="primary" size="default" style="margin-right: 5px" @click="search">查询</Button>
    </div>
    <div class="device-data-list" ref="table">
      <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" height="auto" stripe :row-config="{isHover: true}">
        <vxe-column type="seq" width="60" ></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="120" header-align="center"></vxe-column>
        <vxe-column field="code" :title="showLang('com.group.number')" header-align="center"></vxe-column>
        <vxe-column field="groupName" title="所在分区" header-align="center"></vxe-column>
        <vxe-column field="deviceTypeName" title="设备类型" header-align="center"></vxe-column>
        <vxe-column field="iccid" title="ICCID" header-align="center"></vxe-column>
        <vxe-column field="buildDate" title="安装日期" header-align="center"></vxe-column>
        <vxe-column field="location" title="安装位置" header-align="center"></vxe-column>
        <vxe-column width="220" title="操作" fixed="right">
          <template #header>
            <Button v-if='funCodes(2001)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
            <Button v-if='funCodes(2000)' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button>
          </template>
          <template #default="params">
            <Button v-if='funCodes(2002)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
            <Button v-if='funCodes(2003)' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    <ModalGatewayEdit v-model="editModal" :item="editItem" :smallTypes="smallTypes" @saved="getList" />
  </div>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalGatewayEdit from './ModalGatewayEdit'
import GroupTree from '../../../common/treeGroup/Index'
export default {
  name: 'ConfigDeviceIndex',
  components: {
    ModalGatewayEdit,
    GroupTree,
  },
  props: {
  },
  data() {
    return {
      tabHeight: 300,
      editModal: false,
      checked: true,
      loading: false,
      editItem: {
        isAdd: true,
        data: {}
      },
      list: [],
      total: 0,
      filter: {
        groupId: 0,
        name: '',
        code: '',
        index: 1,
        size: 20,
      },
      smallTypes: [],
    }
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    groupTreeSelectedNode(){
      // console.log('node changed', this.groupTreeSelectedNode)
      this.getList();
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
    ...mapGetters('common', ['getBigTypeName', 'getSmallTypeName', 'getSmallTypes']),
  },
  mounted: function () {
    this.getDeviceTypes();
    setTimeout(this.setTableHeight, 50);
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.initList()
    },
    search: function(){
      this.filter.index = 1;
      this.getList();
    },
    getList: function(){
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.$axios.post(`device/light/QueryGatewayConfig`, this.filter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data.list);
          this.total = res.data.count
        }
      });
    },
    getDeviceTypes: function(){
      this.$axios.post(`device/light/QueryGatewayTypes`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'smallTypes', res.data);
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 50;
    },
    batchDelete() {
      let chks = this.$refs.plTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要删除的网关');
        return false;
      }
      let list = [];
      chks.forEach(el => {
        list.push(el.id)
      });
      this.$Modal.confirm({
        title: "提示",
        content: `确定要批量删除设备吗?`,
        onOk: async () => {
          let res = await this.$axios.post(`device/light/DeleteGateWay`, { list: list });
          if (res.code == 0) {
            this.getList();
            this.$Message.info(`删除成功`, { timeout: 4000 });
          }
        }
      });
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: { } };
      this.editModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.editModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除设备 ${params.row.name}[ID:${params.row.code}] 吗?`,
        onOk: async () => {
          let res = await this.$axios.post(`device/light/DeleteGateWay`, { list: [params.row.id] });
          if (res.code == 0) {
            this.getList();
            this.$Message.info(`删除成功`, { timeout: 4000 });
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.config-device-container {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* padding: 10px; */
  background-color: #F6F6FA;
  border-radius: 8px;
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  background: #fff;
}
.device-search-area {
  height: 42px;
  flex: none;
  /* border: solid 1px #dcdee2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.device-data-list{
  height: 300px;
  flex: auto;
  border: solid 1px #dcdee2;
}
</style>